<template lang="pug">
include ../../configs/template
div.row
  div.col-12
    +select-validation('data.body.additional_info.reason', 'rejectionReasons', 'rejectionReasonStatement', '"name_ukr"', '["required"]')
  div.col-12
    +textarea('data.body.comment', 'comment')
  div.col-12
    v-btn(color="error" @click="setReject") {{$t('setReject')}}
</template>
<script>
import { RejectFormNewAccount } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { rejectNewAccount } from '@/configs/constants'
export default {
  data () {
    return {
      data: {
        body: {
          comment: null,
          additional_info: {
            reason: null,
            cancel_reason: true
          }
        },
        status_document: rejectNewAccount // REJECTED_BY_CREWING
      }
    }
  },
  validations () { return { data: RejectFormNewAccount() } },
  computed: {
    ...mapState({
      rejectionReasons: state => state.directory.rejectionReasons.filter(el => el.model_name === null)
    })
  },
  methods: {
    ...mapActions(['setRejectNewAccountById']),
    async setReject () {
      if (this.$v.$invalid) return this.$v.$touch()
      const { documentID } = this.$route.params
      this.data.documentID = documentID

      this.$swal({
        title: this.$t('warning'),
        text: this.$t('rejectingConfirmation'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.setRejectNewAccountById(this.data)
          if (response) {
            this.$parent.$parent.getData()
            this.data.body = {
              comment: null,
              additional_info: {
                reason: null,
                cancel_reason: true
              }
            }
            this.$v.$reset()
          }
        }
      })
    }
  }
}
</script>
